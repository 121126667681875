// @file Types only used on the Dashboard
import type { AccountKey, SharedCollectionAccountKey, WallViz } from '@@/types'
import type { CollectionKey, WallsFilter } from '@@/types/collections'

export enum MobilePage {
  AccountsMenu = 'AccountsMenu',
  CollectionsMenu = 'CollectionsMenu',
  Collection = 'Collection',
  GettingStarted = 'GettingStarted',
  Join = 'Join',
  More = 'More',
  Search = 'Search',
  LayoutPicker = 'LayoutPicker',
  Learn = 'Learn',
}

export interface DashView {
  accountKey?: AccountKey
  collectionKey?: CollectionKey
  mobilePage?: MobilePage
  page?: string
  filter?: WallsFilter
  gallery?: WallViz | 'all'
  make?: 'make'
  learn?: 'learn'
  sharedCollectionAccountKey?: SharedCollectionAccountKey
  librarySlug?: string
}

export interface NonCurrentTenantAccountsMenuItem {
  name: string
  username: string
  email?: string
  logo: string
  tenantName?: string
  tenantDomainName?: string
  isNative: boolean
  url: string
}
